<template>
    <template v-if="footer">
        <div class="footer-slices">
            <SliceZone :slices="footer.data.slices" :components="components" />
        </div>

        <div class="copyright">
            {{ footer.data.maltCopyright }}
        </div>
    </template>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {SliceZone} from '@prismicio/vue';
    import {useLocale} from '#imports';
    import {useAsyncData} from '#app';
    import {components} from '@navbar-unlogged/slices';
    import {usePrismicDocument} from '@navbar-unlogged/composables';
    import type {PropType} from 'vue';
    import type {PrismicDocument} from '@prismicio/types';

    interface Props {
        prismicDocument?: PrismicDocument;
    }
    const props = defineProps<Props>();

    const footer = ref<PrismicDocument | undefined | null>(props.prismicDocument);
    if (!props.prismicDocument) {
        const {locale} = useLocale();
        const {data} = await useAsyncData('malt-footer', () => usePrismicDocument('malt-footer', locale.value));
        footer.value = data.value;
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .footer-slices {
        display: flex;
        justify-content: flex-end;
        gap: 50px;

        > section {
            flex: 1;
        }

        @include mq.screen_sm {
            flex-direction: column;
            gap: var(--joy-core-spacing-6);

            > section {
                border-bottom: 1px solid rgba(var(--joy-color-neutral-50), 0.3);
                flex: unset;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        margin: 60px 0;
        font-weight: var(--joy-font-weight-normal);
        font-size: var(--joy-font-size-primary-300);
        line-height: var(--joy-line-height-large);
        text-transform: uppercase;
        font-family: var(--joy-font-family);
        color: var(--joy-color-neutral-50);

        @include mq.screen_sm {
            flex-direction: column;
            margin: 16px 0 0 0;
        }
    }
</style>
