<template>
    <footer v-if="visible" class="footer">
        <FooterUnloggedRoot :prismic-document="prismicDocument" />
    </footer>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import {useAuth} from '#imports';
    import FooterUnloggedRoot from './FooterUnloggedRoot.vue';
    import type {PrismicDocument} from '@prismicio/types';

    interface Props {
        alwaysVisible?: boolean;
        prismicDocument?: PrismicDocument;
    }

    const props = withDefaults(defineProps<Props>(), {alwaysVisible: false});

    const auth = useAuth();
    const {loggedIn} = storeToRefs(auth);

    const visible = computed(() => props.alwaysVisible || !loggedIn.value);
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .footer {
        max-width: 1280px;
        @include vars.content-width;
        padding-top: 60px;
        padding-bottom: 60px;

        @include mq.screen_sm {
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }
</style>
